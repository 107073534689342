.Menuarea{
    width: 100%;
    padding:2%;
    padding-left: 7%;
}

@media screen and (min-width: 480px){
    .Menuarea{
        padding-left: 2%;
    }
}