.MenuItem{
    border: 1px solid #ccc;
    box-shadow: 2px 3px rgb(217, 197, 197);
    border-radius: 2%;
    width:95%;
    margin-top: 3px;
    padding: 1%;
}
.ItemName{
    color: brown;
    font-size:0.9rem;
    padding-left: 3%
}
.Price{
    color: black;
    font-size: 0.9rem;
    /* border: 1px solid black; */
    /* text-decoration: underline; */
    float: right;
    padding-right: 7%;
    /* padding-top: 2%; */
}

@media screen and (min-width : 480px) {

    .MenuItem{
        /* width:48%; */
        margin-left:1%;
        margin-right: 1%;
        float: left;
        margin-top: 1%;
    }
    .ItemName{
        font-size:1.6rem;
        padding-left: 1%
    }
    .Price{
        font-size: 1.6rem;
        padding-right: 10%;
    }    
    
}

@media screen and (max-width : 380px) {

    .MenuItem{
        /* width:48%; */
        margin-left:1%;
        margin-right: 1%;
        float: left;
        margin-top: 1%;
    }
    .ItemName{
        font-size:0.7rem;
        padding-left: 1%
    }
    .Price{
        font-size: 0.7rem;
    }    
    
}
