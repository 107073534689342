.FullMenu{
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction:column;
    margin-bottom: 20px;
}
.Booking{
    background-color:brown;
    width: 100%;
    color: white;
    text-align: center;
    font-weight: large;
    font-size: 0.9rem;
    margin-bottom: 10px;
}
.Carouel{
    width:100%;

}
.MainMenu{
    display:flex;
    flex-flow: column;
    width: 99%;
} 
.CarouelContainer{
    width:97%;

}
.OrderForm{
    width: 100%;
    background-color:brown;
    height:80px;
}


.Spin{
    font-size:1.4rem;
    height: 300px;
    display:flex;
    flex-flow:row;
    justify-content:center;
    align-items: center;
}
@media screen and (min-width: 480px) {
    .MainMenu{
        display:flex;
        flex-flow: row;
        width: 99%;
    }   
    .CarouelContainer{
        width:97%;
    
    }
    
    .OrderForm{
        width: 30%;
        background-color:brown;
        height:80px;
    }
    .Booking{
        background-color:brown;
        width: 100%;
        color: white;
        font-size: 1.4rem;
    }
    .Carouel{
        width:100%;
    }
}

/* @media screen and (width: 480px) {
    .MainMenu{
        display:flex;
        flex-flow: column;
        width: 99%;
    }   
} */