.MenuSection{
    width: 100%;
}
.accordion-button:not(.collapsed){
    background-color:brown;
}
.NavLinkOR{
    color: white;
    width: 100%;
    background-color: brown;
    
    font-size:medium;
    font-weight: bold;
}

@media screen and (min-width: 480px) {
    .NavLinkOR {
        font-size:larger;
    }
    .MenuSection{
        width: 85%;
    }
  }