
.CarouslCo{
    width:100%;
}
.cimage{
    height: 100px;
    width: 100%;
}
.CarouselImage1{
    height: 100px;
    width: 100%;
    background-image: url('../../images/16.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.CarouselImage2{
    height: 100px;
    width: 100%;
    background-image: url('../../images/19.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
}
.CarouselImage3{
    height: 100px;
    width: 100%;
    background-image: url('../../images/20.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.CarouselImage4{
    height: 100px;
    width: 100%;
    background-image: url('../../images/25.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
}
@media screen and (min-width:480px) {
    .cimage{
        height:350px;
        width: 100%;
    }
    .CarouslCo{
        width:100%;
    }
    .CarouselImage1{
        height: 350px;
        width: 100%;
        background-image: url('../../images/16.png');
        background-repeat: no-repeat;
        background-size:contain;
        
    }
    .CarouselImage2{
        height: 350px;
        width: 100%;
        background-image: url('../../images/19.png');
        background-repeat: repeat;
        background-size:contain;
        background-position-x: right;
    }
    .CarouselImage3{
        height: 350px;
        width: 100%;
        background-image: url('../../images/20.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .CarouselImage4{
        height: 350px;
        width: 100%;
        background-image: url('../../images/25.png');
        background-repeat: repeat;
        background-size:cover;
    }
}