.NavBGcol{
    background-color:white;
    color:aliceblue;
    height:100px;
}
.Icons{
    color:brown;
    font-size:40px;
    margin-left: 3%;
    margin-top: 10px;
}
.logoStyle{
    color :brown; 
    font-size : 1.5rem; 
    /* border: 1px solid white ;
    border-style: groove;
    box-shadow: 1px 1px white;
    padding: 10px; */
}
.Fssai{
    width: 15%;
    height: 30px;
}
.Whatsapp {
    width: 60px;
    height: 50px;
    margin-left: 3%;
    margin-top: 10px;
}
@media screen and (min-width: 480px) {
    .logoStyle{
        font-size : 3rem; 
  }
  .Fssai{
    width: 15%;
    height: 40px;
}
.Icons{
    
    font-size:50px;
}
}

@media screen and (min-width: 600px){
    .Fssai{
        width: 15%;
        height: 50px;
    }
}