.Sidetabs{
    width: 100%;
    display:flex;
    flex-flow: row;
    
}

.TabListItem{
    width:25%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-bottom: 1px solid rosybrown;
    font-size: 0.9rem;
    color:brown;
    text-align:center;
    font-weight:500;
    cursor: pointer;
}

@media screen and (min-width: 480px) {
    .TabListItem{
        width:100%;
        padding-top: 10%;
        padding-bottom: 10%;
        border-bottom: 1px solid rosybrown;
        font-size: 1.4rem;
        color:brown;
        text-align:center;
        font-weight:500;
        cursor: pointer;
    }
    .Sidetabs{
        width: 15%;
        display:flex;
        flex-flow: column;
    }
  }

/* @media screen and (width: 480px) {
    .Sidetabs{
        flex-flow: row;
    }
} */
@media screen and (max-width: 350px){
    .TabListItem{
    font-size: 0.7rem;
    }
}